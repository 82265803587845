import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Layout from "../components/layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Members = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "meet-sarah.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			trainingImg: file(relativePath: { eq: "services/training.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			socialImg: file(relativePath: { eq: "services/training/social.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
		}
	`);

	const schemaImg = data.heroImg.publicURL;
	const trainingImg = data.trainingImg.childImageSharp.gatsbyImageData;
	const socialImg = data.socialImg.childImageSharp.gatsbyImageData;

	return (
		<Layout>
			<div>
				<meta name="robots" content="noindex, nofollow" />
				<style
					dangerouslySetInnerHTML={{
						__html: `#__memberspace_modal_protected_page {position:fixed; top:0; left:0; width:100%; height:100%; background:#fff; z-index:2147483646;}`,
					}}
				/>
				<div id="__memberspace_modal_protected_page"> </div>
			</div>
			<GatsbySeo
				title="Members Page | Sarah Bennett Commercial Photography"
				description="placeholder text"
				language="en"
				canonical="https://www.sarah-bennett.com/members"
				openGraph={{
					url: "https://www.sarah-bennett.com/members",
					title: "Members Page| Sarah Bennett Commercial Photography",
					description: "placeholder text",
					images: [
						{
							url: `${schemaImg}`,
							width: 800,
							height: 600,
							alt: "Members Page",
						},
					],
				}}
			/>
			<Container>
				<Row className="my-5">
					<Col>
						<h1 className="text-center">Our Courses</h1>
						<hr className="bg-primary w-25 mx-auto" />
					</Col>
				</Row>
				<Row className="justify-content-lg-center">
					<Col lg={4} className="px-3 my-3">
						<Card className="border-0">
							<Card.Img
								as={GatsbyImage}
								className="members-img"
								image={socialImg}
							/>
							<Card.Body>
								<Card.Title>Smart Phone Photography Course</Card.Title>
								<Link
									className="card-link text-center"
									to="/courses/smart-phone-photography-course"
								>
									View Course
								</Link>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={4} className="px-3 my-3">
						<Card className="border-0">
							<Card.Img
								as={GatsbyImage}
								className="members-img"
								image={trainingImg}
							/>
							<Card.Body>
								<Card.Title>Business Of Photography Course</Card.Title>
								<Link
									className="card-link text-center"
									to="/courses/business-of-photography-course"
								>
									View Course
								</Link>
							</Card.Body>
						</Card>
					</Col>
					{/* <Col lg={4} className="px-3 my-3">
            <Card className="border-0">
              <Card.Img
                as={GatsbyImage}
                style={{ height: "250px" }}
                image={socialImg}
              />
              <Card.Body>
                <Card.Title>7 Hidden Iphone Camera Features</Card.Title>
                <Link className="card-link text-center" to="/">
                  View Course
                </Link>
              </Card.Body>
            </Card>
          </Col> */}
				</Row>
			</Container>
		</Layout>
	);
};

export default Members;
